<template>
  <div class="page">
    <div class="header">
      <b-link
        class="tabs-item"
        v-for="(item, i) in tabs"
        :key="i"
        :class="i == active ? 'tabs-active' : ''"
        @click="changeTab(i)"
        >{{ item.title }}</b-link
      >
    </div>
    <div class="list">
      <b-overlay style="min-height: 560px" :show="show" rounded="sm">
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <div class="banner-a"><img :src="item.src" class="banner" /></div>
          <div class="content">
            <div class="content-head">
              <img class="content-avatar" :src="item.avatar" />
              <span class="nickname">{{
                item.user_info ? item.user_info.nickname : "未知用户"
              }}</span>
              <span class="createtime">{{
                $timeFormat(item.createtime, "y-mm-dd hh:MM")
              }}</span>
            </div>
            <div class="content-title">{{ item.title }}</div>
            <div class="content-desc">
              {{ item.content }}
            </div>
            <div class="content-footer">
              <div class="footer-item">
                <b-icon
                  :icon="item.is_like ? 'heart-fill' : 'heart'"
                  style="color: #fb776a"
                ></b-icon>
                {{ item.like_count }}
              </div>
              <div class="footer-item">
                <b-icon icon="chat-dots"></b-icon>
                {{ item.comment_count }}
              </div>
            </div>
          </div>
        </div>
        <Empty v-show="count == 0"></Empty>
      </b-overlay>
    </div>
    <div style="margin: 10px 0; padding-left: 30px">
      <b-pagination
        v-show="count > params.limit"
        v-model="params.page"
        :total-rows="count"
        :per-page="params.limit"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import Empty from "../../../utils/Empty.vue";
export default {
  components: { Empty },
  data() {
    return {
      tabs: [
        { id: "is_recom", title: "推荐" },
        { id: "video", title: "视频" },
      ],
      active: 0,
      list: [],
      params: {
        limit: 5,
        page: 1,
        science_cate_id: "is_recom",
      },
      count: 0,
      show: false,
    };
  },
  mounted() {
    this.getCate();
    this.$nextTick(() => {
      this.getList();
    });
  },
  watch: {
    "params.science_cate_id": function () {
      if (this.params.page != 1) {
        this.params.page = 1;
      } else {
        this.getList();
      }
    },
    "params.page": function () {
      this.getList();
    },
  },
  methods: {
    getCate() {
      this.$request.caterule({}).then((res) => {
        if (res.code == 1) {
          console.log(res.data);
          res.data.forEach((item) => {
            this.tabs.push(item);
          });
        }
      });
    },
    getList() {
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 500);
      this.$request.sciencelist(this.params).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          data.items.forEach((item) => {
            if (item.user_info) {
              item.avatar = this.$host + item.user_info.avatar;
            }
            if (item.resource) {
              item.src = this.$host + item.resource.split(",")[0];
            } else {
              item.src = require("../../../static/my/default_avatar.png");
            }
          });
          this.list = data.items;
          this.count = data.total;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    changeTab(i) {
      this.active = i;
      this.params.science_cate_id = this.tabs[i].id;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 910px;
  padding: 10px 0;
  min-height: 620px;
  background: #ffffff;
}
.header {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
  height: 69px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  .tabs-item {
    margin: 0 15px;
    text-decoration: none;
    color: #666;
    font-size: 18px;
  }
  .tabs-active {
    color: #fd9852;
  }
}
.list {
  padding: 10px 30px;
  min-height: 600px;
  .list-item {
    height: 200px;
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    .banner-a {
      margin-right: 24px;
      width: 146px;
      height: 142px;
    }
    .banner {
      width: 146px;
      height: 142px;
      border-radius: 4px;

      object-fit: cover;
    }
    .content {
      .content-avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
      .content-head {
        height: 32px;
        display: flex;
        align-items: center;
        color: #333;
        .nickname {
          margin: 0 12px;
        }
        .createtime {
          margin: 0 12px;
          font-size: 12px;
        }
      }
      .content-title {
        height: 32px;
        line-height: 32px;
        font-weight: bold;
      }
      .content-desc {
        font-size: 14px;
        color: #666;
        line-height: 22px;
        height: 66px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        overflow: hidden;
      }
      .content-footer {
        height: 32px;
        display: flex;
        align-items: center;
        .footer-item {
          margin-right: 30px;
          color: #666;
          font-size: 14px;
        }
        .footer-item:hover {
          cursor: pointer;
        }
      }
    }
  }
  .list-item:hover {
    cursor: pointer;
  }
}
</style>
